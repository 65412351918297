<template>
	<div>
		<!-- Import shortcut in bottom -->
		<ImportShortcut
			:counter="counter"
			:total="total"
			:is-show="isShowProgressBar && isShowShortcutImport"
			:title="get(importStatus, 'title')"
			@show-import="
				toggleProgressModal({
					isShowProgressBar: true,
					isShowShortcutImport: false,
				})
			"
		/>

		<!-- progress bar modal -->
		<ProgressBarModal
			v-if="isShowProgressBar && !isShowShortcutImport"
			@hide-modal="
				toggleProgressModal({
					isShowProgressBar: true,
					isShowShortcutImport: true,
				})
			"
		/>

		<!-- import success modal -->
		<ImportSuccess
			v-if="isShowImportSuccess"
			@merge-conflict="onMergeConflict"
			@finish-import="finishImport"
		/>
	</div>
</template>

<script>
import {
	GET_IMPORT_STATUS,
	INTERVAL_IMPORT,
	FINISH_IMPORT,
	MERGE_CONFLICT_IMPORT,
} from "@/modules/serviceAppointment/store/action-types"
import {
	CHANGE_PROGRESS_BAR_MODAL_STATUS,
	CHANGE_IMPORT_SUCCESS_MODAL_STATUS,
	CLEAR_INTERVAL_IMPORT,
} from "@/modules/serviceAppointment/store/mutation-types"
import { IMPORT_STATUS } from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("serviceAppointment")
import { get } from "lodash-es"

export default {
	name: "ImportContainer",

	components: {
		ImportShortcut: () => import("@/modules/serviceAppointment/components/ImportShortcut"),
		ProgressBarModal: () => import("@/modules/serviceAppointment/components/ProgressBarModal"),
		ImportSuccess: () => import("@/modules/serviceAppointment/components/ImportSuccess"),
	},

	data() {
		return {
			timeInterval: 1000,
		}
	},

	computed: {
		...mapState([
			"importStatus",
			"importFileId",
			"intervalImport",
			"isShowShortcutImport",
			"isShowProgressBar",
			"isShowImportSuccess",
		]),

		counter() {
			return get(this.importStatus, "done") || 0
		},

		total() {
			return get(this.importStatus, "total") || 100
		},

		checkClearInterval() {
			return (
				!this.importStatus ||
				!this.importFileId ||
				this.importStatus.status === IMPORT_STATUS.IMPORTED
			)
		},
	},

	watch: {
		importFileId: function (val) {
			if (val) {
				this.intervalGetStatus()
			}
		},

		importStatus: {
			deep: true,
			handler() {
				if (this.checkClearInterval) {
					this.CLEAR_INTERVAL_IMPORT()
				}
			},
		},
	},

	created: async function () {
		await this.GET_IMPORT_STATUS({ id: "" })
	},

	methods: {
		get,

		...mapActions({
			GET_IMPORT_STATUS,
			INTERVAL_IMPORT,
			MERGE_CONFLICT_IMPORT,
			FINISH_IMPORT,
		}),
		...mapMutations({
			CHANGE_PROGRESS_BAR_MODAL_STATUS,
			CHANGE_IMPORT_SUCCESS_MODAL_STATUS,
			CLEAR_INTERVAL_IMPORT,
		}),

		toggleProgressModal(params = {}) {
			this.CHANGE_PROGRESS_BAR_MODAL_STATUS(params)
		},

		onMergeConflict: async function (params = {}) {
			await this.MERGE_CONFLICT_IMPORT({ id: params.id })
			this.finishImport(params)
		},

		finishImport(params = {}) {
			this.FINISH_IMPORT({ id: params.id })
		},

		intervalGetStatus() {
			this.INTERVAL_IMPORT(this.timeInterval)
		},
	},
}
</script>

<style lang="scss">
.clean-modal {
	.modal-header {
		display: none;
	}
	.modal-body {
		text-align: center;
		font-weight: 500;
	}
	.modal-footer {
		border-top: none;
	}
}
</style>
